<template>
	<el-dialog
		:title="!dataForm.probId ? '新增资料' : '修改资料'"
		:close-on-click-modal="false"
		:visible.sync="visible"
		width="1200px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="12">
					<el-form-item label="资料种类">
						<el-select class="selItemInput" v-model="dataForm.fileKind" placeholder="请选择">
							<el-option
							  v-for="item in fileKindList"
							  :key="item.displayValue"
							  :label="item.displayName"
							  :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
				<el-form-item label="资料名称">
					<el-input class="selItemInput" v-model="dataForm.fileName" placeholder="请选择"></el-input>
				</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="可编辑(份数)">
						<el-input-number class="selItemInput" v-model="dataForm.editCopies" :min="0" :max="100"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="可编辑版文件">
						<div class="addFileBox" v-for="(item,index) in dataForm.editFileList">
						    <div>
								<span style="display: inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: top;">
									{{item.fileName}}
								</span>
								<i class="el-icon-delete" @click="delFile(index)"></i>
							</div>
						</div>
						<el-upload
						  class="addFileBox"
						  ref="uploadEditFile"
						  :action="action"
						  :http-request="uploadEditFile">
						  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder-add" style="padding: 9px 0 !important;">添加文件</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="签字扫描版(份数)">
						<el-input-number class="selItemInput" v-model="dataForm.scanCopies" :min="0" :max="100"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="签字扫描文件">
						<div class="addFileBox" v-for="(item,index) in dataForm.scanFileList">
						    <div>
								<span style="display: inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: top;">
									{{item.fileName}}
								</span>
								<i class="el-icon-delete" @click="delFile(index)"></i>
							</div>
						</div>
						<el-upload
						  class="addFileBox"
						  ref="uploadScanFile"
						  :action="action"
						  :http-request="uploadScanFile">
						  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder-add" style="padding: 9px 0 !important;">添加文件</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="纸质版(份数)">
						<el-input-number class="selItemInput" v-model="dataForm.paperCopies" :min="0" :max="100"></el-input-number>
					</el-form-item>
				</el-col>
			</el-row>
			  <el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			  </el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import addImg from "@/assets/images/addImg.png"
	import $common from "@/utils/common.js"
	export default {
	  name: "problem-add-or-update",
	  data() {
	    return {
			addImg: addImg,
			id: "",
			visible: true,
			form:{},
			action: "",
			fileKindList:[],
			categoryList:[],
			// subjectList:[],
			majorList:[],
			dataForm:{
				workNo: $common.getItem("workNo"),
				recNo:"",
				fileName:"",
				fileKind:"",
				editCopies:0,
				editFilePath:"",
				subjectNo:$common.getItem("subjectNo"),
				scanCopies:0,
				scanFilePath:"",
				paperCopies: 0,
				editFileList: [],
				scanFileList: []
			},
			dataRule: {

			},
	    };
	  },
	  components: {

	  },
	  activated() {

	  },
	  methods: {
		init(id) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				recNo:"",
				fileName:"",
				fileKind:"",
				editCopies: 0,
				editFilePath:"",
				subjectNo:$common.getItem("subjectNo"),
				scanCopies: 0,
				scanFilePath:"",
				paperCopies: 0,
				editFileList: [],
				scanFileList: []
			}
			this.dataForm.recNo = id?id:0;
			this.getFinishedFile();
			if(id){
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			})

		},
		  getFinishedFile(){
			  this.$http({
				  url:  this.$store.state.httpUrl + "/common/getFinishedFileEdit/" + $common.getItem("workNo")+"/"+$common.getItem("subjectNo"),
				  method: "get",
				  params: {},
			  }).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					  this.fileKindList = data.body;
				  }
			  });
		  },
		getDetail(){
			this.$http({
			  url: this.$store.state.httpUrl + "/business/archivefileinfo/info/"+this.dataForm.recNo,
			  method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				this.dataForm = data.body
			  }
			});
		},
		delFile(index){
			let _fileList = this.dataForm.fileList;
			_fileList.splice(index,1);
			this.dataForm.fileList = _fileList;
		},
		  uploadEditFile(param){
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadEditFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/96/0",
				formData,
				{
				  headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
			  res = res.data
			  if (res.resultCode == 200){
				this.dataForm.editFileList.push({
					fileName: res.body.fileName,
					filePath: res.body.fileSavePath,
					fileUri: res.body.fileUri
				});
				this.dataForm.editFilePath = res.body.fileSavePath;
			  } else {
				this.$message.info('文件上传失败')
			  }
			})
		},
		  uploadScanFile(param){
			  const formData = new FormData();
			  formData.append('file', param.file);
			  this.$refs.uploadScanFile.clearFiles();
			  this.$http.post(
					  this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/96/0",
					  formData,
					  {
						  headers: { 'Content-Type': 'multipart/form-data' }
					  },
			  ).then(res => {
				  res = res.data
				  if (res.resultCode == 200){
					  this.dataForm.scanFileList.push({
						  fileName: res.body.fileName,
						  filePath: res.body.fileSavePath,
						  fileUri: res.body.fileUri
					  });
					  this.dataForm.scanFilePath = res.body.fileSavePath;
				  } else {
					  this.$message.info('文件上传失败')
				  }
			  })
		  },
		dataFormSubmit() {
		  this.$refs["dataForm"].validate(valid => {
		    if (valid) {
			  let _url = "/business/archivefileinfo/save";
			  if(this.dataForm.recNo && this.dataForm.recNo != 0){
				_url = "/business/archivefileinfo/update";
			  }
		      this.$http({
		        url: this.$store.state.httpUrl + _url,
		        method: "post",
		        data: this.$http.adornData(this.dataForm)
		      }).then(({ data }) => {
		        if (data && data.resultCode === 200) {
		          this.$message({
		            message: "操作成功",
		            type: "success",
		            duration: 1500,
		            onClose: () => {
		              this.visible = false;
		              this.$emit("refreshDataList");
		            }
		          });
		        } else {
		          this.$message.error(data.msg);
		        }
		      });
		    }
		  });
		}
	  }
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg{
		width: 1030px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		position: relative;
	}
</style>
